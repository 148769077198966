<template>
	<div>
		<div class="border-bottom">
			<p class="card-text mb-0 pb-1">Images from project</p>
		</div>
		<swiper v-if="images.length" class="swiper-responsive-breakpoints py-2" :options="swiperOptions">
			<swiper-slide v-for="(image, index) in images" :key="index" class="text-center">
				<b-img :src="image" fluid thumbnail />
			</swiper-slide>

			<div slot="button-next" class="swiper-button-next" />
			<div slot="button-prev" class="swiper-button-prev" />
		</swiper>
		<div v-else>
			<div class="text-muted text-center">
				<div class="pt-4">
					<feather-icon icon="AlertCircleIcon" size="56" />
					<p class="mb-0 mt-2">لم يتم ادخال اي صور حتى الان!</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { BImg } from 'bootstrap-vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
	components: {
		BImg,

		Swiper,
		SwiperSlide
	},

	props: {
		images: {
			type: Array,
			default: () => []
		}
	},

	data: () => ({
		swiperOptions: {
			slidesPerView: 1,
			spaceBetween: 60,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			}
		}
	})
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';

.swiper-button-prev,
.swiper-button-next {
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 50%;
	width: 44px;
}

.swiper-button-prev:after {
	background-position: 1px 2px;
	background-size: 40px;
}

.swiper-button-next:after {
	background-position: 4px 3px;
	background-size: 40px;
}

.swiper-slide {
	height: 260px;
}

.swiper-slide img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}
</style>
