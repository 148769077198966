<template>
	<div>
		<div v-if="loading" class="">
			Loading...
		</div>
		<div v-else class="">
			<b-alert variant="secondary" show>
				<div class="alert-body d-flex align-items-center py-1">
					<div class="mr-1">
						<feather-icon icon="InfoIcon" size="24" class="mr-1" />
						<span>Make sure project data is up to date</span>
					</div>
					<b-button variant="outline-secondary" pill @click="syncProject">
						Sync data with MIS
					</b-button>
				</div>
			</b-alert>
			<div v-if="error" class="">
				<div class="text-muted text-center">
					<div class="pt-3">
						<feather-icon icon="AlertCircleIcon" size="56" />
						<p class="mb-0 mt-2">
							لم يتم تعبئة استمارة المسح القاعدي للمجتمعات المستفيدة من مشروع-جميع القطاعات
						</p>
					</div>
				</div>
			</div>
			<b-card v-else :title="projectData.name">
				<b-row>
					<b-col cols="12" md="6" lg="4">
						<div>
							<div class="border-bottom">
								<p class="card-text mb-0 pb-1">
									Project statistics
								</p>
							</div>

							<b-row class="mt-3">
								<b-col v-for="item in charts" :key="item.icon" cols="6">
									<b-media no-body class="mb-3">
										<b-media-aside class="mr-2">
											<b-avatar size="56" :variant="item.color">
												<feather-icon size="24" :icon="item.icon" />
											</b-avatar>
										</b-media-aside>
										<b-media-body class="my-auto ">
											<h4 class="mb-0">
												<span class="font-weight-bolder font-small-4 mb-0">
													{{ getStats(item.key) }}
												</span>
											</h4>
											<b-card-text class="font-small-3 font-weight-bold mb-0">
												{{ item.subtitle }}
											</b-card-text>
										</b-media-body>
									</b-media>
								</b-col>
							</b-row>
						</div>
					</b-col>
					<b-col cols="12" md="6" lg="4">
						<project-status :data="{ series: [projectDetails.progress] }" />
					</b-col>
					<b-col cols="12" lg="4">
						<project-images class="mb-md-3" :images="projectDetails.images" />
					</b-col>
				</b-row>

				<hr class="mb-2 mt-0" />
				<div class="">
					<div class="d-flex py-50" style="gap: 1rem">
						<h4 class="mb-0 mr-2">Services</h4>
						<b-row class="flex-fill">
							<b-col v-for="service in services" :key="service.mode" cols="12" sm="4" md="3">
								<b-media class="d-flex mb-md-0 mb-2 service-card" no-body>
									<b-media-aside>
										<b-avatar rounded size="42" :variant="service.avatarVariant">
											<feather-icon size="18" :icon="service.avatar" />
										</b-avatar>
									</b-media-aside>
									<b-media-body>
										<h6 class="transaction-title mb-0">
											{{ service.mode }}
										</h6>
										<span class="font-weight-bolder d-block font-small-4 mb-1">
											{{ service.count }}
										</span>
										<div v-for="(type, i) in service.types" :key="i" class="py-50 border-top">
											<p class="d-flex justify-content-between" style="direction: ltr">
												<span>{{ type.name }}</span>
												<b-badge>
													{{ type.count }}
												</b-badge>
											</p>
										</div>
									</b-media-body>
								</b-media>
							</b-col>
						</b-row>
					</div>
					<hr class="my-2" />
					<div class="" style="gap: 2rem">
						<h4 class="mb-2 mr-2">Economic activity</h4>
						<b-row>
							<b-col
								v-for="transaction in economics"
								:key="transaction.mode"
								cols="6"
								class="mb-1 d-flex justify-content-between"
							>
								<b-media class="d-flex flex-fill align-items-center" no-body>
									<b-media-body>
										<h6 class="transaction-title">
											{{ transaction.mode }}
										</h6>
									</b-media-body>
								</b-media>
								<div class="font-weight-bolder" :class="transaction.deduction ? 'text-danger' : 'text-success'">
									{{ getActivity(transaction.key) }}
								</div>
							</b-col>
						</b-row>
					</div>
				</div>
			</b-card>
		</div>
	</div>
</template>

<script>
import store from '@/store';
import { BCard, BCardText, BAlert, BButton, BMedia, BMediaAside, BBadge, BAvatar, BMediaBody, BRow, BCol } from 'bootstrap-vue';
import ProjectStatus from './project-status.vue';
import ProjectImages from './project-images.vue';

export default {
	name: 'ProjectDetails',

	components: {
		BCardText,
		BAlert,
		BButton,
		BMedia,
		BMediaAside,
		BBadge,
		BAvatar,
		BMediaBody,
		BRow,
		BCol,
		BCard,

		ProjectStatus,
		ProjectImages,
	},

	data: () => ({
		projectData: null,
		projectDetails: null,
		error: null,
		loading: false,

		charts: [
			{
				icon: 'UsersIcon',
				color: 'light-primary',
				key: 'population',
				subtitle: 'Population',
				customClass: 'mb-2 mb-xl-0',
			},
			{
				icon: 'UserMinusIcon',
				color: 'light-info',
				key: 'unemployment',
				subtitle: 'Unemployment',
				customClass: 'mb-2 mb-xl-0',
			},
			{
				icon: 'PackageIcon',
				color: 'light-danger',
				key: 'services',
				subtitle: 'Services',
				customClass: 'mb-2 mb-sm-0',
			},
			{
				icon: 'LayersIcon',
				color: 'light-success',
				key: 'ngos',
				subtitle: 'NGOs',
				customClass: '',
			},
		],
		economics: [
			{ mode: 'Internal migration', key: 'internalMigration' },
			{
				mode: 'Foreign migration',
				key: 'foreignMigration',
			},

			{
				mode: 'Good living families',
				key: 'good_living_families',
			},
			{
				mode: 'Medium living families',
				key: 'medium_living_families',
			},
			{
				mode: 'Poor living families',
				key: 'poor_living_families',
			},
			{
				mode: 'Very poor living families',
				key: 'very_poor_living_families',
			},
		],
	}),

	computed: {
		hasConsultant() {
			return !!this.projectData?.consultant_name;
		},

		services() {
			const services = [
				{
					mode: 'Education',
					count: '02',
					avatar: 'PenToolIcon',
					avatarVariant: 'light-primary',
					types: [
						{
							name: this.projectDetails?.eduService,
							count: this.projectDetails?.eduServiceCount,
						},
					],
					show: !!this.projectDetails?.eduService,
				},
				{
					mode: 'Health',
					count: '03',
					avatar: 'PocketIcon',
					avatarVariant: 'light-success',
					types: [{ name: this.projectDetails?.healthService, count: 1 }],
					show: !!this.projectDetails?.healthService,
				},
				{
					mode: 'Sanitation',
					count: '02',
					avatar: 'RepeatIcon',
					avatarVariant: 'light-danger',
					types: [{ name: this.projectDetails?.sanitation, count: 1 }],
					show: !!this.projectDetails?.sanitation,
				},
				{
					mode: 'Water',
					count: '01',
					avatar: 'TruckIcon',
					avatarVariant: 'light-warning',
					types: [{ name: this.projectDetails?.waterSource, count: 1 }],
					show: !!this.projectDetails?.waterSource,
				},
			];

			return services.filter((s) => s.show);
		},
	},

	async created() {
		this.loading = true;
		try {
			this.projectDetails = await store.dispatch('projects/getProjectDetails', this.$route.params.id);
			this.projectData = await store.dispatch('projects/getProject', this.$route.params.id);
		} catch (error) {
			console.error(error);
			this.error = 'Sorry! project not exist!';
		}

		this.loading = false;
	},

	methods: {
		getStats(key) {
			if (key === 'services') return this.services.length;
			if (key === 'unemployment') return `${this.projectDetails[key]}%`;
			return this.projectDetails[key] ?? 0;
		},

		getActivity(key) {
			if (key.endsWith('Migration')) return `${this.projectDetails[key]}%`;

			return this.projectDetails[key] ?? 0;
		},

		async syncProject() {
			try {
				await store.dispatch('projects/syncProject', this.$route.params.id);
			} catch (error) {
				console.log(error);
			}
		},
	},
};
</script>

<style lang="scss">
.tabs-nav {
	background-color: transparent;
}

.chat-application {
	border: 1px solid #edecf3;
}

.service-card {
	background-color: #f7f7f7;
	padding: 0.8rem;
	border-radius: 4px;
	border: 1px solid #f1f0f5;
	height: 100%;
}
</style>
