<template>
	<b-card v-if="data" no-body>
		<div class="border-bottom d-flex justify-content-between pb-1">
			<b-card-text class="mb-0">
				Project Progress
			</b-card-text>
		</div>

		<vue-apex-charts type="radialBar" height="250" class="my-2" :options="goalOverviewRadialBar" :series="data.series" />
	</b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue';
import VueApexCharts from 'vue-apexcharts';
import { $themeColors } from '@themeConfig';

const $strokeColor = '#ebe9f1';
const $textHeadingColor = '#5e5873';
const $goalStrokeColor2 = '#51e5a8';

export default {
	components: {
		VueApexCharts,
		BCard,
		BCardText
	},

	props: {
		data: {
			type: Object,
			default: () => ({ completed: '786,617', inProgress: '13,561', series: [0] })
		}
	},

	data() {
		return {
			goalOverviewRadialBar: {
				chart: {
					height: 245,
					type: 'radialBar',
					sparkline: {
						enabled: true
					},
					dropShadow: {
						enabled: true,
						blur: 3,
						left: 1,
						top: 1,
						opacity: 0.1
					}
				},
				colors: [$goalStrokeColor2],
				plotOptions: {
					radialBar: {
						offsetY: -10,
						startAngle: -150,
						endAngle: 150,
						hollow: {
							size: '77%'
						},
						track: {
							background: $strokeColor,
							strokeWidth: '50%'
						},
						dataLabels: {
							name: {
								show: false
							},
							value: {
								color: $textHeadingColor,
								fontSize: '2.86rem',
								fontWeight: '600'
							}
						}
					}
				},
				fill: {
					type: 'gradient',
					gradient: {
						shade: 'dark',
						type: 'horizontal',
						shadeIntensity: 0.5,
						gradientToColors: [$themeColors.success],
						inverseColors: true,
						opacityFrom: 1,
						opacityTo: 1,
						stops: [0, 100]
					}
				},
				stroke: {
					lineCap: 'round'
				},
				grid: {
					padding: {
						bottom: 10
					}
				}
			}
		};
	}
};
</script>
